import AboutSection from "components/AboutSection/AboutSection";
import AgencySection from "components/AgencySection/AgencySection";
import BannerOne from "components/BannerSection/BannerOne";
import CallToSection from "components/CallToSection/CallToSection";
import FunFactsOne from "components/FunFacts/FunFactsOne";
import HeaderOne from "components/Header/HeaderOne";
import MobileMenu from "components/Header/MobileMenu";
import Layout from "components/Layout/Layout";
import MainFooter from "components/MainFooter/MainFooter";
import ParallaxSection from "components/ParallaxSection/ParallaxSection";
import Style from "components/Reuseable/Style";
import SearchPopup from "components/SearchPopup/SearchPopup";
import TrustedSection from "components/TrustedSection/TrustedSection";
import React from "react";
import ServicesSectionThree from "components/ServicesSection/ServicesSectionThree";

const HomeMain = () => {
  return (
    <Layout pageTitle="Home">
      <Style />
      <HeaderOne />
      <MobileMenu />
      <SearchPopup />
      <BannerOne />
      <AboutSection />
      <AgencySection />
      <ServicesSectionThree className="padd-top" />
      <FunFactsOne />
      <TrustedSection />
      <ParallaxSection />
      <CallToSection />
      <MainFooter normalPadding={false} />
    </Layout>
  );
};

export default HomeMain;
