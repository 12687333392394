import image1 from "assets/images/resource/featured-image-1.jpg";
import image3 from "assets/images/resource/featured-image-15.jpg";
import image2 from "assets/images/resource/featured-image-2.jpg";
import image12 from "assets/images/update-01-10-2021/resources/about-10-1-1.jpg";
import image11 from "assets/images/update-01-10-2021/resources/about-11-1-3.jpg";
import image10 from "assets/images/update-01-10-2021/resources/about-eight-1-1.png";
import image9 from "assets/images/update-01-10-2021/resources/about-nine-1-1.png";
import image6 from "assets/images/update-01-10-2021/resources/about-seven-1-1.jpg";
import image7 from "assets/images/update-01-10-2021/resources/about-seven-1-2.jpg";
import image8 from "assets/images/update-01-10-2021/resources/about-six-1-1.jpg";
import image4 from "assets/images/update-1-12-2020/resources/about-1-1.jpg";
import image5 from "assets/images/update-1-12-2020/resources/signture-1-1.png";
import image13 from "assets/images/update-26-02-2021/resources/about-me-1.jpg";

export const aboutSectionOne = {
  image1,
  image2,
  title: "About Us",
  text1:
    "We are committed to providing our customers with exceptional service while offering our employees the best training.",
  text2:
    "Founded in 2021 and with its headquarters in Centurion, Bounty Group ZA(BGZA) is a one stop solution for your business and home needs. We partner with the best and leading local and multinational companies while leveraging huge warehouse spaces from large distributors in the region to ensure an uninterrupted supply of goods and services.\nSince its inception, BGZA have been helping customers build a better, more sustainable world and are committed and contributing to reduced business interruptions.Our innovative products and services, backed by the best and big role players in each field, provide exceptional value that helps customers succeed.\nBGZA does business in the SADC region, principally operating through six(6) primary segments – Information and Communication Technology(ICT), Energy(Solar, Uninterrupted Power Supplies, Fuel and coal), Projects(mining, construction and building finishings and maintenance), Staff(training and recruitment), Engineering(Plant maintenance and supplies) and Bounty Creatives(Corporate Branding and advertising) solutions, and including other general supplies to cater for business and home needs.",
  textList: [
    "Suspe ndisse suscipit sagittis leo.",
    "Entum estibulum dignissim posuere.",
    "If you are going to use a passage.",
  ],
  since: "2021",
};

export const aboutSectionTwo = {
  image: image3,
  title: "We are standout\nexperts in business",
  text: "We believe that success is achieved through a highly collaborative interaction, so that we can work together to identify and evaluate opportunities beyond your current operations.",
  counters: [
    { id: 1, percent: 90, title: "Quality\nServices" },
    { id: 2, percent: 50, title: " Skilled\nEmployee" },
  ],
  since: "2008",
};

export const aboutSectionThree = {
  image: image4,
  title: "The Right People for your Business",
  subtitle: "welcome to business agency",
  text: "There are many variations of passages of available but the majority have suffered alteration in some form, by injected humou or randomised words which don look even slightly believable.",
  lists: [
    "If you are going to use a passage",
    "Lorem Ipsum is not simply random text",
    "Making this the first true generator on the Internet",
  ],
  barTitle: "Bussiness Success",
  barPercent: 70,
  aboutPerson: { name: "William Smith", tagline: "Co Founder", image: image5 },
};

export const aboutSeven = {
  images: [image6, image7],
  tagline: "About Company",
  title: "We provide the best business services",
  text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, simply free text by injected humour, or randomised.",
  title2: "Linoor is the largest digital marketing agency.",
  lists: [
    "We’re professional and certified designers",
    "We use quality material to make your walls awesome",
    "We care about our customers satisfaction",
  ],
};

export const aboutSix = {
  tagline: "Our Company Benefits",
  title: "Mission is to protect your business",
  text: "There are many variations of passages of available but the majority have suffered alteration in some form, injected humou or randomised words slightly believable.",
  image: image8,
  items: [
    {
      id: 1,
      title: "We combine tech with business",
      icon: "flaticon-idea",
    },
    {
      id: 2,
      title: "The right people for your businesses",
      icon: "flaticon-mouse",
    },
    {
      id: 3,
      title: "Best way of running a business",
      icon: "flaticon-briefcase",
    },
  ],
};

export const aboutNine = {
  image: image9,
  icon: "flaticon-data",
  title: "Powerful Tools for Your Customers",
  titleHighlight: "Tools",
  text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  lists: [
    "Refresing to get such a personal touch.",
    "Duis aute irure dolor in reprehenderit in voluptate.",
    "Velit esse cillum dolore eu fugiat nulla pariatur.",
  ],
};

export const aboutEight = {
  image: image10,
  icon: "flaticon-data",
  title: "Control Everything from One Solution",
  titleHighlight: "Everything",
  text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. fur hat and fur boa who sat upright, raising a heavy fur muff that covered.",
  progresses: [
    {
      id: 1,
      text: "Marketing",
      count: 77,
    },
    {
      id: 2,
      text: "SEO Optimization",
      count: 89,
    },
  ],
};

export const aboutEleven = {
  images: ["about-11-1-1.jpg", "about-11-1-2.jpg"],
  tagline: "Our Company Introduction",
  title: "We Ensure Only Quality Consulting Services for our Clients",
  text: "Quis aliqua sunt nisi consectetur anim ullamco ea deserunt non voluptate nisi sit elit exercitation eu Lorem. Mollit occaecat nisi occaecat fugiat ullamco.",
  boxes: [
    {
      id: 1,
      icon: "flaticon-headhunting",
      title: "Qualified Consultants",
    },
    {
      id: 2,
      icon: "flaticon-background-check",
      title: "Trusted Since 1987",
    },
  ],
  progressTitle: "Finance & Consulting",
  percent: 77,
  image: image11,
  name: "Christine Eve",
  infoText: "Co Founder & Consultant",
};

export const aboutTen = {
  image: image12,
  tagline: "Why Choose Us",
  title: "The Largest Business Experts",
  text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  boxes: [
    {
      id: 1,
      title: "Customer Experience",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Dolor sit amet, adipsicing elit.",
    },
    {
      id: 2,
      title: "Customer Experience",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Dolor sit amet, adipsicing elit.",
    },
  ],
};

export const aboutMeOne = {
  image: image13,
  title: "Jessica brown",
  text: "Lorem ipsum dolor sit amet, con adipiscing elit tiam convallis elit id impedie. Quisq commodo simply free ornare tortor. Excepteur sint occaecat sunt in culpa qui officia deserunt mollit.",
  text2:
    "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
  text3: "I help my clients stand out and they help me grow.",
  socials: [
    {
      id: 1,
      icon: "fab fa-twitter",
      href: "#",
    },
    {
      id: 2,
      icon: "fab fa-facebook-f",
      href: "#",
    },
    {
      id: 3,
      icon: "fab fa-linkedin-in",
      href: "#",
    },
    {
      id: 4,
      icon: "fab fa-pinterest-p",
      href: "#",
    },
  ],
  certificates: ["about-certificate-1.jpg", "about-certificate-2.jpg"],
};

export const aboutMeCounter = {
  title: "My Skills",
  counters: [
    {
      id: 1,
      title: "Wordpress \n development",
      percent: 89,
    },
    {
      id: 2,
      title: "React \n Vue/Nuxt",
      percent: 89,
    },
    {
      id: 3,
      title: "Website \n designing",
      percent: 89,
    },
  ],
};
