import React from "react";
import { Col } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";
import Link from 'next/link';

interface ServicesType {
  service: {
    id?: number;
    icon: string;
    title: string;
    text: string;
    href: string;
  }
}

const SingleService = ({ service }:ServicesType) => {
  const { icon, title, text, href } = service;

  return (
    <Col xl={3} lg={6} md={6} sm={12} className="service-block-two">
      <div className="inner-box animated fadeInUp">
        <div className="bottom-curve"></div>
        <div className="icon-box">
          <span className={icon}></span>
        </div>
        <h5>
          <Link href={href}>
            <a>
              <TextSplit text={title} />
            </a>
          </Link>
        </h5>
        <div className="text">{text}</div>
        <div className="link-box">
          <Link href={href}>
            <a>
              <span className="fa fa-angle-right"></span>
            </a>
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default SingleService;
