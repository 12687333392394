import { useRootContext } from "context/context";
import { useEffect, useRef } from "react";
import useScroll from "./useScroll";
import { useAppStore } from "lib/store";

const useActive = (id) => {
  // const ref = useRef();
  // const { setCurrentActive } = useRootContext();
  // const { scroll } = useScroll();

  // useEffect(() => {
  //   if (ref.current) {
  //     if (ref.current.offsetTop <= scroll + 100) {
  //       setCurrentActive(id);
  //     }
  //   }
  // }, [scroll, setCurrentActive, id]);


  const { loading, error, setActive, active } = useAppStore();
  const ref = useRef<HTMLDivElement>(null);
  const { scroll } = useScroll();

  useEffect(() => {
    if (ref.current) {
      if (ref.current.offsetTop <= scroll + 100) {
        setActive(id)
      }
    }
  }, [scroll, setActive, id]);
  return ref;
};

export default useActive;
