const home = {
  id: 1,
  name: "Home",
  href: "/"
};

const services = [
  {
    id: 1,
    icon: "flaticon-vector",
    title: "Tech\nSolutions",
    text: "Computer, Software, Hardware.",
    href: "/tech-solutions"
  },
  {
    id: 2,
    icon: "flaticon-group",
    title: "Staff\nSolutions",
    text: "Recruitment, Training.",
    href: "/staff-solutions"
  },
  {
    id: 3,
    icon: "flaticon-instant-camera",
    title: "Bounty\nCreative",
    text: "Corporate  Branding, Advertising.",
    href: "/bounty-creative"
  },
  {
    id: 4,
    icon: "flaticon-monitor",
    title: "Project\nSolutions",
    text: "Motor Graders, Rigid Trucks, Track Type Tractors.",
    href: "/project-solutions"
  },
  {
    id: 5,
    icon: "flaticon-idea",
    title: "Engineering\nSolutions",
    text: "Plant maintenance and more.",
    href: "/engineering-solutions"
  }, {
    id: 6,
    icon: "flaticon-global",
    title: "Energy\nSolutions",
    text: "Fuel, Solar, Batteries, UPS, Coal, Oil.",
    href: "/energy-solutions"
  },
  {
    id: 7,
    icon: "flaticon-background-check",
    title: "General\nSupplies",
    text: "Stationary, Personal Protective Equipment.",
    href: "/general-supplies"
  },
];

const servicesSection = {
  title: "LET’S CREATE SOMETHING\ntogether",
  text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised.",
  services,
};

export const servicesSectionThree = {
  title: "What we’re offering",
  services,
};

export default servicesSection;

export const serviceSectionThree = [
  {
    id: 1,
    icon: "flaticon-responsive",
    href: "/tech-solutions",
    title: "Tech\nSolutions",
  },
  {
    id: 2,
    icon: "flaticon-people",
    href: "/staff-solutions",
    title: "Staff\nSolutions",
  },
  {
    id: 3,
    icon: "flaticon-digital-marketing",
    href: "/bounty-creative",
    title: "Bounty\nCreative",
  },
  {
    id: 4,
    icon: "flaticon-development",
    href: "/project-solutions",
    title: "Project\nSolutions",
  },
  {
    id: 5,
    icon: "flaticon-app-development",
    href: "/engineering-solutions",
    title: "Engineering\nSolutions",
  },
  {
    id: 6,
    icon: "flaticon-ui",
    href: "/energy-solutions",
    title: "Energy\nSolutions",
  }, {
    id:7,
    icon: "flaticon-background-check",
    href: "/general-supplies",
    title: "General\nSupplies",
  },
];

export const servicesSectionOne = {
  title: "We Shape the Perfect\nSolutions",
  text: "We are committed to providing our customers with exceptional service while offering our employees the best training.",
  services: serviceSectionThree,
};

export const serviceSix = {
  tagline: "Checkout Our Services",
  title: "What we’re offering",
  services: [
    {
      id: 1,
      image: "service-1-1.jpg",
      title: "Business Growth",
      href: "/digital-marketing",
      icon: "flaticon-mobile-analytics",
      text: "Aliq is notm hendr erit a augue insu image pellen simply freed text ipsum.",
    },
    {
      id: 2,
      image: "service-1-2.jpg",
      title: "Marketing Advice",
      href: "/digital-marketing",
      icon: "flaticon-research",
      text: "Aliq is notm hendr erit a augue insu image pellen simply freed text ipsum.",
    },
    {
      id: 3,
      image: "service-1-3.jpg",
      title: "Finance Consulting",
      href: "/digital-marketing",
      icon: "flaticon-creative",
      text: "Aliq is notm hendr erit a augue insu image pellen simply freed text ipsum.",
    },
  ],
};

export const serviceSeven = [
  {
    id: 1,
    icon: "flaticon-laptop",
    href: "/digital-marketing",
    title: "Speed Optimization",
    text: "Lorem ipsum is are many variations of pass of majority.",
  },
  {
    id: 2,
    icon: "flaticon-presentation",
    href: "/digital-marketing",
    title: "Marketing Analysis",
    text: "Lorem ipsum is are many variations of pass of majority.",
  },
  {
    id: 3,
    icon: "flaticon-target",
    href: "/digital-marketing",
    title: "SEO and Backlinks",
    text: "Lorem ipsum is are many variations of pass of majority.",
  },
  {
    id: 4,
    icon: "flaticon-visualization",
    href: "/digital-marketing",
    title: "Content Marketing",
    text: "Lorem ipsum is are many variations of pass of majority.",
  },
];

export const serviceEight = {
  text: "Our Services List",
  title: "What We’re Offering",
  services: [
    {
      id: 1,
      title: "Consumer Products",
      href: "/digital-marketing",
      icon: "flaticon-vector-4",
    },
    {
      id: 2,
      title: "Audit Marketing",
      href: "/digital-marketing",
      icon: "flaticon-analysis",
    },
    {
      id: 3,
      title: "Advice Bankings",
      href: "/digital-marketing",
      icon: "flaticon-business",
    },
    {
      id: 4,
      title: "Marketing Rules",
      href: "/digital-marketing",
      icon: "flaticon-global",
    },
  ],
};

export const serviceNine = [
  {
    id: 1,
    image: "service-9-1.jpg",
    icon: "flaticon-responsive",
    title: "modern designing",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/ui-designing",
  },
  {
    id: 2,
    image: "service-9-2.jpg",
    icon: "flaticon-computer",
    title: "graphic designing",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/graphic-designing",
  },
  {
    id: 3,
    image: "service-9-3.jpg",
    icon: "flaticon-global",
    title: "digital marketing",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/digital-marketing",
  },
  {
    id: 4,
    image: "service-9-4.jpg",
    icon: "flaticon-development",
    title: "Content Writting",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/seo",
  },
  {
    id: 5,
    image: "service-9-5.jpg",
    icon: "flaticon-app-development",
    title: "App Development",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/app-development",
  },
  {
    id: 6,
    image: "service-9-6.jpg",
    icon: "flaticon-planning",
    title: "ui / ux designing",
    text: "Lorem ipsum is simply free sed qui magni dolores eos qui voptam.",
    href: "/ui-designing",
  },
];
