import image from "assets/images/resource/featured-image-6.jpg";

const agencySection = {
  image,
  title: "Our..",
  text: "These are the fundamental values guiding our work, our reputation and legacy depends on  these.",
  tabBtns: [
    {
      id: "tab-1",
      text: "Mission",
    },
    {
      id: "tab-2",
      text: "Vision",
    },
    {
      id: "tab-3",
      text: "Values",
    },
  ],
  tabContents: [
    {
      id: "tab-1",
      text: "To be the enabler in communities of customers and stakeholders by creating one stop solution",
    },
    {
      id: "tab-2",
      text: "To be the organization that best understands and satisfies the product, service, and to provide solutions that support communities and protect the planet while providing self-fulfillment needs of consumers and businesses through multiple interventions.",
    },
    {
      id: "tab-3",
      text: "We value Safety, Integrity, Transparency, Accountability, Striving for excellence and Long-term partnerships.",
    },
  ],
  textList: [
    "Safety and Integrity.",
    "Transparency.",
    "Accountability.",
    "Striving for excellence and Long-term partnerships."
  ],
};

export default agencySection;
