import image from "assets/images/resource/featured-image-5.jpg";

const trustedSection = {
  title: "We’re Committed To Deliver...",
  title2: "We’re trusted by\nthousands of clients",
  image,
  author: "Brian Halligan, CEO & CO-Founder of HubSpot",
  text: "\"Your focus should include creating communities outside of your site for people to connect with you, your products, and others within the community. Ultimately, this 'outside' focus will drive people back to your site.\"",
  textList: [
    "Customer Satisfaction.",
    "Better Customer Experience.",
    "Proactive Customer Support.",
    "Delightful Service Experience",
  ],
  features: [
    {
      id: 1,
      title: "HIGH QUALITY PRODUCTS",
      subtitle: "------",
    },
    {
      id: 2,
      title: "OUTSTANDING SERVICES.",
      subtitle: "-------",
    },
  ],
};

export default trustedSection;
